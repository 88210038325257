/*eslint camelcase: ['error', {properties: 'never'}]*/
import Vue from 'vue'
import config from '@/config'

const SET_FETCHING_APPS = 'SET_FETCHING_APPS'
const SET_APPS = 'SET_APPS'

const moduleApps = {
  state: {
    apps: {},
    isFetchingApps: false
  },
  mutations: {
    [SET_FETCHING_APPS](state, isFetching) {
      Vue.set(state, 'isFetchingApps', isFetching)
    },
    [SET_APPS](state, {clientId, data}) {
      Vue.set(state.apps, clientId, data)
    }
  },
  actions: {
    listApps({ commit, state }, { clientId }) {
      if (state.apps[clientId]) {
        return new Promise((resolve) => {resolve(state.apps[clientId])})
      }
      commit(SET_FETCHING_APPS, true)
      const url = `${config.API_URL}/clients/${clientId}/apps`
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url)
          .then(response => {
            if (response.status !== 200) {
              commit(SET_FETCHING_APPS, false)
              reject(response)
            }
            commit(SET_FETCHING_APPS, false)
            commit(SET_APPS, {clientId: clientId, data: response.data.apps })
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_FETCHING_APPS, false)
            reject(error)
          })
      })
    },
  },
  getters: {
    fetchingApps: state => state.isFetchingApps,
    app: (state) => (id) => {
      return state.apps[id] || []
    }
  }
}

export default moduleApps
