<template>
  <div>
    <AppBanner
      v-if="banner"
      :banner-title="$t('BANNERS.' + banner.name)"
      :banner-type="banner.type"
    />
    <div class="content-container">
      <transition :name="transition">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import AppBanner from '@/components/AppBanner'

export default {
  name: 'AppContainer',
  components: { AppBanner },
  computed: {
    transition () {
      return this.$store.getters.transition
    },
    banner () {
      return this.$store.getters['banners/banner']
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.content-container {
  position: relative;
  background-color: #fff;
  width: 100%;
  max-width: 800px;
  margin: 90px auto;
  padding: 20px 50px;
  overflow: hidden;
  border: #eee 1px solid;
  border-radius: 5px;
}

// Responsive
@media only screen and (max-width: 800px) {
  .content-container {
    max-width: 100%;
    margin: 0;
    padding: 20px;
  }
}
</style>
