// Load vue relative library
import Vue from 'vue'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'

// Load extern library
import axios from 'axios'
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

// Load intern component
import App from './App.vue'
import router from './router'

// import store from './store'
import storeModule from './store'
import i18n from './languages/i18n'
// import '../theme/index.css'
import './assets/style/app.scss'

// Setup
Vue.config.productionTip = false

// Setup Vue instance
Vue.use(VueAxios, axios)
Vue.use(Vuex)

// Store
const store = new Vuex.Store({
  modules: storeModule
})

// SET DEVICE SIZE
store.dispatch('setMode')

// SET LANGUAGE
i18n.locale = store.getters.language

// Interceptor to redirect on standard error, and intercept and store
// the token returned by the back-end
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    store.dispatch('login')
  }
  return Promise.reject(error.response ? error.response : error)
})

// scroll to top and init page's title when route changes
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  document.title = i18n.t(to.meta.title)
  next()
})

/* eslint-disable no-console */
console.info(`Version:%c ${process.env.VERSION}`, 'color: #FD3745;')
/* eslint-enable no-console */

if (process.env.SENTRY_ENV){
  const currentHost = window.location.hostname.split(".")

  if (currentHost.length == 3 && currentHost[0] == 'switch') {
    currentHost.splice(0, 1)
    Sentry.init({
      Vue,
      dsn: 'https://e183c51513d74a539ffd58b0743ecc96@sentry.numberly.net/416',
      environment: process.env.SENTRY_ENV,
      release: process.env.VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [currentHost.join('.')],
        }),
      ],
      tracesSampleRate: 1.0,
    })
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
