import i18n from '@/languages/i18n'
import Vue from 'vue'
import { get, set } from 'tiny-cookie'

const SET_LANGUAGE = 'SET_LANGUAGE'

const moduleLanguage = {
  state: {
    language: get('lang') || 'en',
    supportedLang: ['en', 'fr']
  },
  mutations: {
    [SET_LANGUAGE] (state, lang) {
      Vue.set(state, 'language', lang)
    }
  },
  actions: {
    setLanguage ({ commit }, lang) {
      commit(SET_LANGUAGE, lang)
      set('lang', lang)
      i18n.locale = lang
    }
  },
  getters: {
    language: state => state.language,
    supportedLang: state => state.supportedLang
  }
}

export default moduleLanguage
