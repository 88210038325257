<template>
  <div
    class="app-banner"
    :class="bannerType"
  >
    {{ bannerTitle }}
  </div>
</template>

<script>
export default {
  name: 'AppBanner',
  props: {
    bannerTitle: {
      type: String,
      default: ""
    },
    bannerType: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss">
.app-banner {
  position: absolute;
  top:0;
  left:0;
  right:0;

  &.error {
    background: #EFD15B;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: #333;
  }
}
</style>
