import Vue from 'vue'
import config from '@/config'

/* eslint camelcase: ["error", {properties: "never"}] */

const CLEAR_SESSION = 'CLEAR_SESSION'
const SET_USER = 'SET_USER'

const moduleAuth = {
  state: {
    user: null
  },
  mutations: {
    [SET_USER] (state, user) {
      Vue.set(state, 'user', user)
    },
    [CLEAR_SESSION] (state) {
      Vue.set(state, 'user', null)
    }
  },
  actions: {
    getProfile ({ commit }) {
      const url = config.API_URL + '/clients'
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status >= 300) {
              reject(response)
              return
            }
            commit(SET_USER, response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    login ({ commit }) {
      commit(CLEAR_SESSION)
      // redirect to the login page
      window.location = '/api/login'
    }
  },
  getters: {
    user: state => state.user
  }
}

export default moduleAuth
