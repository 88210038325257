const config = {
  API_URL: '/api',

  mode: {
    MOBILE: '0',
    DESKTOP: '1'
  }
}

export default config
