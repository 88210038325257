/*eslint camelcase: ['error', {properties: 'never'}]*/
import Vue from 'vue'
import config from '@/config'

const SET_FETCHING_CLIENTS = 'SET_FETCHING_CLIENTS'
const SET_CLIENTS = 'SET_CLIENTS'
const SET_CLIENT = 'SET_CLIENT'

const moduleClient = {
  state: {
    client: null,
    clients: [],
    isFetchingClients: true
  },
  mutations: {
    [SET_FETCHING_CLIENTS](state, isFetching) {
      Vue.set(state, 'isFetchingClients', isFetching)
    },
    [SET_CLIENTS](state, clients) {
      Vue.set(state, 'clients', clients)
    },
    [SET_CLIENT](state, client) {
      Vue.set(state, 'client', client)
    }
  },
  actions: {
    listClients({ commit, state }) {
      if (!state.isFetchingClients) {
        return new Promise((resolve) => {resolve(state.clients)})
      }

      const url = config.API_URL + '/clients'
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(url)
          .then(response => {
            if (response.status !== 200) {
              commit(SET_FETCHING_CLIENTS, false)
              reject(response)
            }
            commit(SET_FETCHING_CLIENTS, false)
            commit(SET_CLIENTS, response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_FETCHING_CLIENTS, false)
            reject(error)
          })
      })
    },
    setClient({ commit }, client) {
      commit(SET_CLIENT, client)
    }
  },
  getters: {
    fetchingClients: state => state.isFetchingClients,
    clients: state => state.clients,
    client: (state) => (id) => {
      return state.clients.find(cl => cl.id === id)
    }
  }
}

export default moduleClient
