import LoaderGeneric from './Generic'

export default {
  name: 'LoaderCardItem',
  functional: true,
  render (h, { data }) {
    return (
      <LoaderGeneric {...data} height={150} width={200} speed={1}>
        <rect x="50" y="125" rx="4" ry="4" width="100" height="10" />
        <circle cx="105" cy="60" r="42" />
      </LoaderGeneric>
    )
  }
}
