<template>
  <div class="content">
    <logo
      class="client-logo"
      :src="client.logo_url"
      :height="100"
      :width="350"
    />

    <div>
      <router-link
        v-if="multiClient"
        class="clients-link text-xs"
        to="/clients"
      >
        <i class="material-icons clients-link__icon">&#xE5C4;</i>
        <span class="clients-link__text">{{ $t('CLIENT_SWITCH') }}</span>
      </router-link>
    </div>

    <div
      v-if="fetchingApps"
      class="loader-wrapper"
    >
      <LoaderCardItem class="loader-card" />
      <LoaderCardItem class="loader-card" />
      <LoaderCardItem class="loader-card" />
    </div>
    <apps-list
      v-else
      :items="apps"
      :callback="goToApp"
    />
  </div>
</template>

<script>
import AppsList from '@/components/AppsList'
import Logo from '@/components/Logo'
import LoaderCardItem from '@/components/Loader/Card'

export default {
  name: 'AppsListPage',
  components: { AppsList, Logo, LoaderCardItem },
  computed: {
    fetchingApps () {
      return this.$store.getters.fetchingApps
    },
    multiClient () {
      return this.$store.getters.clients.length > 1
    },
    client () {
      return this.$store.getters.client(this.$route.params.client)
    },
    apps () {
      return this.$store.getters['app'](this.client.id)
    }
  },
  created () {
    this.$store.dispatch('setTransition', 'slide-right')
  },
  mounted () {
    this.$store.dispatch('listApps', { clientId: this.client.id})
  },
  methods: {
    goToClients () {
      this.$router.push({ name: 'clients' })
    },
    goToApp (app) {
      window.location = app.url
    }
  }
}
</script>

<style scoped lang="scss">

.loader-wrapper {
  margin-top: 30px;
}
.loader-card {
  display: inline-block;
  width: calc((800px / 3) - 32px);
  height: 150px;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid #e4e6ec;
}

.clients-header-wrapper {
  padding: 0 15px;
}

.client-logo {
  padding: 70px 10px 25px;
}

.content {
  position: relative;
}

.clients-link {
  float:left;
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  color: white;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  padding: 12px 20px;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  transition: .1s;
  color: #00a5b5;
  background-color: rgba(0, 165, 181, 0.15);
  &:hover {
    color: darken(#00a5b5, 10%);
  }

  .clients-link__icon,
  .clients-link__text {
    vertical-align: middle;
  }

  .clients-link__icon {
    font-size: 18px;
  }
}
</style>
