<template>
  <div class="text-center">
    <div
      class="logo-container"
      :style="styleObject"
      :alt="title"
      :title="title"
    />
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    'src': {
      type: String,
      required: true
    },
    'width': {
      type: Number,
      default: 150
    },
    'height': {
      type: Number,
      default: 150
    },
    'title': {
      type: String,
      default: ''
    },
  },
  computed: {
    styleObject () {
      return {
        'width': this.width + 'px',
        'height': this.height + 'px',
        'background-image': 'url(\'' + this.src + '\')'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo-container {
    display: inline-block;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
  }
</style>
