<template>
  <div class="content">
    <div class="clients-header-wrapper">
      <h1 class="clients-title">
        <div class="title">
          {{ $t('MAIN_TITLE') }}
        </div>
        <div class="clients-title__subtitle">
          {{ $t('WELCOME_SUBTITLE') }}
        </div>
      </h1>

      <div
        v-show="fetchingClients || clients.length > 0"
        class="search-input"
      >
        <input
          v-model="search"
          type="text"
          class="search-input__input"
          name="client-search"
          :placeholder="$t('CLIENT_SEARCH')"
        >
        <span class="search-input__suffix">
          <i class="material-icons search-input__icon">&#xE8B6;</i>
        </span>
      </div>
    </div>

    <div
      v-if="fetchingClients"
      class="loader-wrapper"
    >
      <LoaderCardItem class="loader-card" />
      <LoaderCardItem class="loader-card" />
      <LoaderCardItem class="loader-card" />
      <LoaderCardItem class="loader-card" />
      <LoaderCardItem class="loader-card" />
      <LoaderCardItem class="loader-card" />
    </div>
    <clients-list
      v-else-if="clients.length > 0"
      :items="filteredClients"
      :callback="goToClient"
    />
    <div
      v-else
      class="clients-title__subtitle"
      style="margin-top: 30px;"
    >
      <div class="no-clients__box">
        <div>
          <img
            :src="noDataSVG"
            alt="Empty logo"
          >
          <div style="margin-top: 20px;">
            {{ $t('NO_CLIENTS_REGISTERED') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint camelcase: ["error", {properties: "never"}]*/
import LoaderCardItem from '@/components/Loader/Card'
import ClientsList from '@/components/ClientsList'
import noData from '@/assets/img/no-data.svg'

export default {
  name: 'ClientsListPage',
  components: { ClientsList, LoaderCardItem },
  data () {
    return {
      search: '',
      noDataSVG: noData
    }
  },
  computed: {
    fetchingClients () {
      return this.$store.getters.fetchingClients
    },
    clients () {
      return this.$store.getters.clients
    },
    filteredClients () {
      // Remove accent, following this https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
      const search = this.search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
      return this.clients.filter(
        client => {
          return client.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search)
        })
    }
  },
  created () {
    this.$store.dispatch('setTransition', 'slide-left')
  },
  methods: {
    goToClient (client) {
      this.$router.push({ 'name': 'apps', params: { client: client.id } })
    }
  }
}
</script>

<style lang="scss">
.clients-title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;

  .title {
    color: #00A6B6;
  }

  .clients-title__subtitle {
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0 40px;
    color: #999;

    .no-clients__box {
      display: inline-block;
      width: 80%;
      padding-top: 10%;
      padding-bottom: 13%;
      background-color: #E0F9FC;
    }
  }
}

.loader-wrapper {
  margin-top: 30px;
}
.loader-card {
  display: inline-block;
  width: calc((800px / 3) - 32px);
  height: 150px;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid #e4e6ec;
}

.clients-header-wrapper {
  padding: 0 15px;
}

.search-input {
  position: relative;
  font-size: 14px;
  width: 70%;
  border: 2px solid #dedede;
  border-radius: 5px;
  margin: 0 auto;

  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c0c4cc;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #c0c4cc;
  }

  input::-ms-input-placeholder { /* Microsoft Edge */
    color: #c0c4cc;
  }

  .search-input__input {
    -webkit-appearance: none;
    border: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0;
    width: 100%;
    outline: none;
    background-color: transparent;
    margin-left: 45px;
    font-size: 14px;
  }

  .search-input__suffix {
    position: absolute;
    height: 100%;
    left: 13px;
    top: 0;
    text-align: center;

    .search-input__icon {
      color: #c0c4cc;
      width: 25px;
      line-height: 40px;
    }
  }
}
</style>
