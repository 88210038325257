import config from '@/config'
import Vue from 'vue'

const SET_MODE = 'SET_MODE'
const SET_TRANSITION = 'SET_TRANSITION'

const moduleLayout = {
  state: {
    mode: '',
    transition: 'slide-left'
  },
  mutations: {
    [SET_MODE] (state, mode) {
      Vue.set(state, 'mode', mode)
    },
    [SET_TRANSITION] (state, transition) {
      Vue.set(state, 'transition', transition)
    }
  },
  actions: {
    setMode ({ commit }) {
      if (screen.width < 800) {
        commit(SET_MODE, config.mode.MOBILE)
      } else {
        commit(SET_MODE, config.mode.DESKTOP)
      }
    },
    setTransition ({ commit }, transition) {
      commit(SET_TRANSITION, transition)
    }
  },
  getters: {
    mode: state => state.mode,
    transition: state => state.transition
  }
}

export default moduleLayout
