<template>
  <ul
    v-if="items.length > 0"
    class="cards-container"
  >
    <li
      v-for="item in items"
      :key="item.id"
      class="card-item"
    >
      <a
        class="card-item__content"
        @click="callback(item)"
      >
        <logo
          v-if="item.logo"
          :src="item.logo"
          :title="item.name"
          :height="80"
          :width="150"
        />
        <div class="card-item__title text-center text-semibold">
          {{ item.name }}
        </div>
      </a>
    </li>
  </ul>
  <div
    v-else
    class="clients-title__subtitle text-center"
    style="margin-top: 30px;"
  >
    <div class="no-clients__box">
      <div>
        <img
          :src="noDataSVG"
          alt="Empty logo"
        >
        <div style="margin-top: 20px;">
          {{ $t('NO_APPS_REGISTRED') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo"
import noData from '@/assets/img/no-data.svg'

export default {
  name: "AppsList",
  components: { Logo },
  props: {
    items: {
      type: Array,
      required: true
    },
    callback: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      noDataSVG: noData
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
}

.cards-container {
  list-style-type: none;
  padding: 30px 0;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: auto;
}

.clients-title__subtitle {
  font-size: 15px;
  font-weight: 400;
  margin: 5px 0 40px;
  color: #999;

  .no-clients__box {
    display: inline-block;
    width: 80%;
    padding-top: 10%;
    padding-bottom: 13%;
    background-color: #E0F9FC;
  }
}

.card-item {
  width: calc((800px / 3) - 32px);
  height: 150px;
  margin: 15px;

  border-radius: 5px;
  border: 1px solid #e4e6ec;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.03);
  color: #303133;
  cursor: pointer;
  transition: box-shadow;
  -webkit-transition: box-shadow 0.3s; /* Safari */
  &:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  }

  .card-item__content {
    padding: 10px;
    height: calc(150px - 2 * 10px);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .card-item__title {
    width: 100%;
    color: #848484;
    margin-top: 10px;
    border-top: 1px solid #eee;
    padding-top: 10px;
  }
}

// Responsive
@media only screen and (max-width: 800px) {
  .cards-container {
    justify-content: center;
  }
}
</style>
