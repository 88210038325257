/*eslint camelcase: ["error", {properties: "never"}]*/
import Vue from 'vue'

const SET_BANNER = 'SET_BANNER'
const CLEAN_BANNER = 'CLEAN_BANNER'

const moduleBanners = {
  namespaced: true,
  state: {
    banner: null
  },
  mutations: {
    [SET_BANNER] (state, banner) {
      Vue.set(state, 'banner', banner)
    },
    [CLEAN_BANNER] (state) {
      Vue.set(state, 'banner', null)
    }
  },
  actions: {
    setBanner ({ commit }, reason) {
      commit(SET_BANNER, { name: reason, type: 'error' })
    },
    cleanBanner ({ commit }) {
      commit(CLEAN_BANNER)
    }
  },
  getters: {
    banner: state => state.banner
  }
}

export default moduleBanners
