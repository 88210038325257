import moduleLanguage from './lang'
import moduleAuth from './auth'
import moduleLayout from './layout'
import moduleClient from './client'
import moduleBanners from './banners'
import moduleApps from './apps'

export default {
  language: moduleLanguage,
  auth: moduleAuth,
  layout: moduleLayout,
  client: moduleClient,
  banners: moduleBanners,
  apps: moduleApps
}
