import Vue from 'vue'
import Router from 'vue-router'
// Containers
import AppContainer from '@/pages/AppContainer'
import ClientsListPage from '@/pages/ClientsListPage'
import AppsListPage from '@/pages/AppsListPage'
import i18n from '@/languages/i18n'

Vue.use(Router)

// Pages

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: AppContainer,
      redirect: '/clients',
      children: [
        {
          name: 'clients',
          path: 'clients',
          component: ClientsListPage,
          meta: { title: 'META_TITLE_LIST_CLIENTS' }
        },
        {
          name: 'apps',
          path: 'clients/:client',
          component: AppsListPage,
          meta: { title: 'META_TITLE_LIST_APPS' },
          beforeEnter: checkClientIsSet
        }
      ],
      beforeEnter: guardRoute
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

function bannerConsume (to, store) {
  if (to.query && to.query['reason']) {
    store.dispatch('banners/setBanner', to.query['reason'])
    return true
  }
  store.dispatch('banners/cleanBanner')
  return false
}

function guardRoute (to, from, next) {
  // work-around to get to the Vuex store (as of Vue 2.0)
  const store = router.app.$options.store
  const user = store.getters.user
  if (!user) {
    const promises = [
      store.dispatch('getProfile'),
      store.dispatch('listClients'),
    ]
    Promise.all(promises.map(p => p.catch(e => e))).then((res) => {
      const errors = res.filter(result => result.status && result.status >= 400)
      if (res[0].status === 401 || res[0].status === 403) {
        store.dispatch('login')
      }
      else if (errors.length > 0) {
        window.location = '/502.html'
      } else {
        if (res[0].data.lang) {
          i18n.locale = res[0].data.lang
        }
        checkOnlyOneClient(to, from, next)
      }
    })
  } else {
    next()
  }
}

function checkOnlyOneClient (to, from, next) {
  const store = router.app.$options.store
  const userClients = store.getters.user
  const bannerIsSet = bannerConsume(to, store)
  if (!bannerIsSet && userClients.length === 1) {
    store
      .dispatch('listApps', { clientId: userClients[0].id})
      .then((res) => {
        if (res.apps.length === 1) {
          window.location.replace(res.apps[0].url)
          return
        }
        router.push({name: 'apps', params: { client: userClients[0].id } })
      }).catch(next)
  } else {
    next()
  }
}

function checkClientIsSet (to, from, next) {
  const store = router.app.$options.store
  const client = store.getters.client(to.params.client)
  bannerConsume(to, store)
  if (!client) {
    router.push({ name: 'clients' })
  } else {
    next()
  }
}

export default router
